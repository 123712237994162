export default class Urplan {
    constructor(url, selector) {
        this.url = url;
        this.selector = selector;
        this.wrapper = this.createWrapper();
        this.iframe = this.createIframe(url);
        this.overlay = this.createOverlay();

        if (selector) {
            this.addClickListener(selector);
        }

        this.addOverlayClickListener();
        this.addResizeListener();
        this.addIframeLoadListener();
    }

    createWrapper() {
        const wrapper = document.createElement('div');
        wrapper.style.position = 'fixed';
        wrapper.style.overflow = 'hidden';
        wrapper.style.width = '490px';
        wrapper.style.height = '650px';
        wrapper.style.top = 'calc(50% - 296px)'; // Centering vertically considering hidden part
        wrapper.style.left = '50%';
        wrapper.style.zIndex = '1001';
        wrapper.style.bottom = '50%';
        wrapper.style.right = '50%';
        wrapper.style.transform = 'translate(-50%, -50%)';
        wrapper.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
        wrapper.style.display = 'none';
        wrapper.style.opacity = '0';
        wrapper.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
        wrapper.style.borderRadius = '10px';
        wrapper.style.overflow = 'hidden';
        document.body.appendChild(wrapper);
        return wrapper;
    }

    createIframe(url) {
        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.style.width = "100%";
        iframe.style.height = "calc(100% + 48px)";
        iframe.style.marginTop = "-48px";
        iframe.style.border = "0";
        iframe.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
        this.wrapper.appendChild(iframe);
        this.updateIframeStyle();
        return iframe;
    }

    createOverlay() {
        const overlay = document.createElement('div');
        overlay.style.display = 'none';
        overlay.style.position = 'fixed';
        overlay.style.top = '0';
        overlay.style.left = '0';
        overlay.style.width = '100%';
        overlay.style.height = '100%';
        overlay.style.zIndex = '1000';
        overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        overlay.style.transition = 'opacity 0.5s ease';
        overlay.style.opacity = '0';
        document.body.appendChild(overlay);
        return overlay;
    }

    addClickListener(selector) {
        this.elements = document.querySelectorAll(selector);
        this.elements.forEach(element => {
            element.addEventListener('click', () => this.show());
        });

        // Handle dynamically added elements
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(node => {
                        if (node.matches && node.matches(selector)) {
                            node.addEventListener('click', () => this.show());
                        }
                    });
                }
            });
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    }

    addOverlayClickListener() {
        this.overlay.addEventListener('click', () => this.hide());
    }

    addResizeListener() {
        window.addEventListener('resize', () => this.updateIframeStyle());
    }

    updateIframeStyle() {
        if (this.wrapper) {
            if (window.innerWidth <= 768) {
                this.wrapper.style.width = '100%';
                this.wrapper.style.height = '100%';
                this.wrapper.style.top = '0';
                this.wrapper.style.left = '0';
                this.wrapper.style.transform = 'none';
                this.wrapper.style.borderRadius = '0';
            } else {
                this.wrapper.style.width = '490px';
                this.wrapper.style.height = '650px';
                this.wrapper.style.top = '50%';
                this.wrapper.style.left = '50%';
                this.wrapper.style.transform = 'translate(-50%, -50%)';
                this.wrapper.style.borderRadius = '10px';
            }
        }
    }

    addIframeLoadListener() {
        this.iframe.addEventListener('load', () => {
            try {
                const iframeLocation = this.iframe.contentWindow.location.href;
                const iframeUrl = new URL(iframeLocation);
                if (iframeUrl.hostname !== 'f.veylinx.com' && iframeLocation !== this.currentUrl.href) {
                    console.log(`Iframe attempted to redirect to: ${iframeLocation}`);
                    window.location.href = iframeLocation;
                } else {
                    this.currentUrl = iframeUrl;
                }
            } catch (error) {
                console.log('Redirect detected to a cross-origin URL', error);
                const iframeSrcUrl = new URL(this.iframe.src);
                if (iframeSrcUrl.hostname !== 'f.veylinx.com') {
                    window.location.href = this.iframe.src;
                }
            }
        });
    }
    
    show() {
        this.wrapper.style.display = 'block';
        this.overlay.style.display = 'block';
        setTimeout(() => {
            this.overlay.style.opacity = '1';
            this.wrapper.style.opacity = '1';
        }, 10);
    }

    hide() {
        this.overlay.style.opacity = '0';
        this.wrapper.style.opacity = '0';
        setTimeout(() => {
            this.wrapper.style.display = 'none';
            this.overlay.style.display = 'none';
        }, 500);
    }
}